.create-questioner-form .title {
  font-size: 2rem;
  max-width: 30rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #953232;
  font-weight: 600;
}

.create-questioner-form .card {
  border: 1px solid #EBEBEB;
  padding: 1rem;
  border-radius: 0;
}

.create-questioner-form .card.active {
  border-color: #953232;
}

.create-questioner-form .btn {
  font-weight: 600;
  border-radius: 0;
  border: none;
  font-size: 1rem;
  padding-left: 10px;
  padding-right: 10px;
}

.create-questioner-form .btn.btn-gray {
  background-color: #F5F5F5;
  color: #14262E;
}

.create-questioner-form .btn.icon {
  padding: 0 10px 0 0;
  display: inline-flex;
  align-items: center;
}

.create-questioner-form .btn.icon > img {
  display: block;
  max-width: unset;
  margin-right: .5rem;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
}

.create-questioner-form .btn.mover:hover {
  filter: invert(1);
}

.create-questioner-form .public-DraftEditor-content {
  min-height: 12rem;
  font-family: 'Futura';
}

.create-questioner-form .editor-root {
  border-radius: 0;
}

.create-questioner-form .editor-root:focus-within {
  border-color: #953232;
}

.create-questioner-form .toolbar > div > div {
  z-index: 8;
}

.create-questioner-form .r-divider {
  border-right: 1px solid #CECECE;
}

.create-questioner-form .b-divider {
  border-bottom: 1px solid #CECECE;
}

.create-questioner-form .question-number {
  width: 25px;
  height: 25px;
  text-align: center;
  color: white;
  background-color: #953232;
  margin-right: 1rem;
  border-radius: 100%;
  font-size: 1rem;
  font-weight: 600;
}

.create-questioner-form .question-field .read-only-title {
  font-size: 1rem;
  font-weight: 600;
}

.create-questioner-form .question-field .option {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 30rem;
}

.create-questioner-form .question-field .option-radio {
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  border: 1px solid #707070;
  margin-right: 1rem;
  background-color: transparent;
}

.create-questioner-form .question-field .option-radio.active {
  border: 1px solid #953232;
  position: relative;
}

.create-questioner-form .question-field .option-radio.active::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 100%;
  background-color: #953232;
}

.create-questioner-form .question-field .option-text {
  border: none;
  font-size: 1rem;
  padding-right: 4rem;
  height: 2rem;
  flex: 1;
}

.create-questioner-form .question-field .option-text:focus {
  outline: none;
  border-bottom: 1px solid #953232;
}

.create-questioner-form .question-field .option-text:focus:read-only {
  outline: none;
  border: none;
}

.create-questioner-form .question-field .option-remove {
  position: absolute;
  border: none;
  background: transparent;
  right: 1.5rem;
}

.create-questioner-form .question-field .option-add-text {
  background: transparent;
  border: none;
  font-size: 1rem;
  font-style: italic;
  color: #989898;
}
